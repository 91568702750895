import React from 'react';
import { css } from '@emotion/react';
import jonHandPhone from '../images/img/jon-hand-phone-2b.png';
import kayakLogo from '../images/svg/kayak.svg';
import uaLogo from '../images/svg/ua.svg';
// import mmr from '../images/img/mmr1.jpg';
import check from '../images/svg/check.svg';
import { randy } from '../functions';

const color = "white";
const opacity = 0;
const transform = "rotate(-2) skewX(-6.5) skewY(7)";
const flightPrice = randy({ min: 101, max: 210, count: 3, order: "desc" });
const uaTextCSS = css`font-size: 90px; fill: white`;
const uaPasswordCSS = css`font-size: 120px; fill: white; font-family: courier, courier new, serif;`;

const Phone = () => {
  return (
    <svg viewBox="0 0 2300 3300" className="no-select">
      <image x={0} y={0} width={2300} height={3300} xlinkHref={jonHandPhone} />
      <rect
        x={480} y={140} width={1150} height={2500} fill="black" stroke="red" strokeWidth={0}
        transform={ transform }
        rx={100}
      />

      {/* kayak */}
      <image x={600} y={300} width={900} href={kayakLogo}
        className="svg001 svgK"
        transform={ transform }
        opacity={ opacity }
      />
      <text x={530} y={800}
        className="svg002 svgK"
        transform={ transform }
        opacity={ opacity }
        css={ css`
          font-size: 150px;
          fill: ${color};
        `}
      >searching...</text>
      <text x={595} y={800}
        className="svg003 svgK"
        transform={ transform }
        opacity={ opacity }
        css={ css`
          font-size: 100px;
          fill: ${color};
        `}
      >Best available flights</text>

      {/* begin flights */}
      {/* 1 */}
      <circle cx={500} cy={1100}
        className="svg004 svgK"
        transform={ transform }
        opacity={ opacity }
        r={40}
        stroke={color}
        strokeWidth={5}
      />
      <text x={600} y={1100}
        className="svg004 svgK"
        transform={ transform }
        opacity={ opacity }
        css={ css`
          font-size: 60px;
          fill: ${color};
        `}
      >United Airlines</text>
      <text x={600} y={1200}
        className="svg004 svgK"
        transform={ transform }
        opacity={ opacity }
        css={ css`
          font-size: 60px;
          fill: ${color};
        `}
      >4:25 PM - 7:57 AM</text>
      <text x={1150} y={1120}
        className="svg004 svgK"
        transform={ transform }
        opacity={ opacity }
        css={ css`
          font-size: 80px;
          fill: ${color};
        `}
      >{"$"}{ flightPrice[ 0 ] }</text>

      {/* 2 */}
      <circle cx={470} cy={1400}
        className="svg005 svgK"
        transform={ transform }
        opacity={ opacity }
        r={40}
        stroke={color}
        strokeWidth={5}
      />
      <text x={580} y={1400}
        className="svg005 svgK"
        transform={ transform }
        opacity={ opacity }
        css={ css`
          font-size: 60px;
          fill: ${color};
        `}
      >Delta Airlines</text>
      <text x={572} y={1500}
        className="svg005 svgK"
        transform={ transform }
        opacity={ opacity }
        css={ css`
          font-size: 60px;
          fill: ${color};
        `}
      >7:43 AM - 1:51 PM</text>
      <text x={1120} y={1420}
        className="svg005 svgK"
        transform={ transform }
        opacity={ opacity }
        css={ css`
          font-size: 80px;
          fill: ${color};
        `}
      >{"$"}{ flightPrice[ 1 ] }</text>

      {/* 3 */}
      <circle cx={440} cy={1700}
        className="svg006 svgK"
        transform={ transform }
        opacity={ opacity }
        r={40}
        stroke={color}
        strokeWidth={5}
      />
      <text x={550} y={1700}
        className="svg006 svgK"
        transform={ transform }
        opacity={ opacity }
        css={ css`
          font-size: 60px;
          fill: ${color};
        `}
      >JetBlue Airways</text>
      <text x={545} y={1800}
        className="svg006 svgK"
        transform={ transform }
        opacity={ opacity }
        css={ css`
          font-size: 60px;
          fill: ${color};
        `}
      >4:40 PM - 8:40 PM</text>
      <text x={1091} y={1720}
        className="svg006 svgK"
        transform={ transform }
        opacity={ opacity }
        css={ css`
          font-size: 80px;
          fill: ${color};
        `}
      >{"$"}{ flightPrice[ 2 ] }</text>

      {/* book button */}
      <rect x={550} y={2200}
        className="svg007 svgK"
        transform={ transform }
        opacity={ opacity }
        width={ 500 }
        height={ 250 }
        css={ css`
          fill: #ff690f;
        `}
      />
      <text x={690} y={2350}
        className="svg007 svgK"
        transform={ transform }
        opacity={ opacity }
        css={ css`
          font-size: 80px;
          fill: #ffffff;
        `}
      >BOOK</text>

      {/* UA */}
      <image x={600} y={250} width={900} href={uaLogo}
        className="svg020 svgUA"
        transform={ transform }
        opacity={ opacity }
      />
      {/* sign in */}
      <text x={600} y={1050}
        className="svg021 uaSignIn"
        transform={ transform }
        opacity={ opacity }
        css={ css`
          font-size: 90px;
          fill: #ffffff;
        `}
      >Sign-in</text>
      {/* sign in boxes */}
      <rect className="svg022 uaSignIn"
        x={530} y={1200} width={1050} height={300} stroke="white" strokeWidth={5}
        opacity={ opacity } transform={ transform }
        rx={20}
      />
      <text x={600} y={1380} className="svg022 svg022text uaSignIn"
        transform={ transform }
        opacity={ opacity }
        css={ css`
          font-size: 90px;
          fill: #888888;
        `}
      >username</text>
      <rect className="svg023 uaSignIn"
        x={520} y={1600} width={1050} height={300} stroke="white" strokeWidth={5}
        opacity={ opacity } transform={ transform }
        rx={20}
      />
      <text x={590} y={1780} className="svg023 svg023text uaSignIn"
        transform={ transform }
        opacity={ opacity }
        css={ css`
          font-size: 90px;
          fill: #888888;
        `}
      >password</text>
      <text x={600} y={1380} className="svg024 uaSignIn" css={uaTextCSS}
        transform={ transform }
        opacity={ opacity }
      >j</text>
      <text x={640} y={1380} className="svg024 uaSignIn" css={uaTextCSS} transform={ transform } opacity={ opacity }
      >o</text>
      <text x={710} y={1380} className="svg024 uaSignIn" css={uaTextCSS} transform={ transform } opacity={ opacity }
      >n</text>
      <text x={785} y={1380} className="svg024 uaSignIn" css={uaTextCSS} transform={ transform } opacity={ opacity }
      >s</text>
      <text x={845} y={1380} className="svg024 uaSignIn" css={uaTextCSS} transform={ transform } opacity={ opacity }
      >p</text>
      <text x={920} y={1380} className="svg024 uaSignIn" css={uaTextCSS} transform={ transform } opacity={ opacity }
      >e</text>
      <text x={990} y={1380} className="svg024 uaSignIn" css={uaTextCSS} transform={ transform } opacity={ opacity }
      >w</text>
      <text x={1075} y={1380} className="svg024 uaSignIn" css={uaTextCSS} transform={ transform } opacity={ opacity }
      >a</text>
      <text x={1140} y={1380} className="svg024 uaSignIn" css={uaTextCSS} transform={ transform } opacity={ opacity }
      >k</text>

      <text x={580} y={1800} className="svg025 uaSignIn" css={uaPasswordCSS} transform={ transform } opacity={ opacity }
      >*</text>
      <text x={660} y={1800} className="svg025 uaSignIn" css={uaPasswordCSS} transform={ transform } opacity={ opacity }
      >*</text>
      <text x={740} y={1800} className="svg025 uaSignIn" css={uaPasswordCSS} transform={ transform } opacity={ opacity }
      >*</text>
      <text x={820} y={1800} className="svg025 uaSignIn" css={uaPasswordCSS} transform={ transform } opacity={ opacity }
      >*</text>
      <text x={900} y={1800} className="svg025 uaSignIn" css={uaPasswordCSS} transform={ transform } opacity={ opacity }
      >*</text>
      <text x={980} y={1800} className="svg025 uaSignIn" css={uaPasswordCSS} transform={ transform } opacity={ opacity }
      >*</text>
      <text x={1060} y={1800} className="svg025 uaSignIn" css={uaPasswordCSS} transform={ transform } opacity={ opacity }
      >*</text>
      <text x={1140} y={1800} className="svg025 uaSignIn" css={uaPasswordCSS} transform={ transform } opacity={ opacity }
      >*</text>

      <text x={750} y={1050}
        className="svg026 svgUA"
        transform={ transform }
        opacity={ opacity }
        css={ css`
          font-size: 90px;
          fill: #ffffff;
        `}
      >Hi, Jon!</text>

      <text x={620} y={1350}
        className="svg027 svgUA"
        transform={ transform }
        opacity={ opacity }
        css={ css`
          font-size: 90px;
          fill: #ffffff;
        `}
      >Shoes connected</text>
      <circle cx={500} cy={1310}
        className="svg027 svgUA"
        transform={ transform }
        opacity={ opacity }
        r={60}
        stroke="#dddddd"
        strokeWidth={5}
      />

      <path className="svg029 svgUA" d="M525,1330c-2.4-4.45-4.79-8.9-7.19-13.36c13.18,18.74,22.36,40.28,26.75,62.77
        c51.1-47.26,85.78-112.02,143.69-150.63"
        css={css`
          fill:none;
          stroke:#ff690f;
          stroke-width:20;
          stroke-miterlimit:10;
          visibility: hidden;
        `}
      />

      <text x={580} y={2050}
        className="svg030 svgUA"
        transform={ transform }
        opacity={ opacity }
        css={ css`
          font-size: 90px;
          fill: #ffffff;
        `}
      >Your activity</text>

      <ellipse className="svg031 svgUA"
        cx={-2080} cy={985} rx={450} ry={450}
        stroke="#ff690f" strokeWidth={40}
        strokeLinecap="round"
        transform="rotate(-87) "
        css={css`fill:none; visibility:hidden;`}
      />

      <text x={780} y={2040}
        className="svg032 svgUA"
        transform={ transform }
        opacity={ opacity }
        css={ css`
          font-size: 90px;
          fill: #ffffff;
        `}
      >Keep going!</text>

    </svg>
  );
}
export default Phone;
